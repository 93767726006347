<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 48 48"
  width="24"
  height="24"
  ><path fill="#fff" fill-opacity=".01" d="M0 0h48v48H0z"></path><path
    stroke="#000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="4"
    d="M41.4004 11.551 36.3332 5H11.6666l-5.08262 6.551"></path><path
    fill="#2F88FF"
    stroke="#000"
    stroke-linejoin="round"
    stroke-width="4"
    d="M6 13c0-1.1046.89543-2 2-2h32c1.1046 0 2 .8954 2 2v27c0 1.6569-1.3431 3-3 3H9c-1.65685 0-3-1.3431-3-3V13Z"
  ></path><path
    stroke="#fff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="4"
    d="m32 27-8 8-8-8M23.9917 19v16"></path></svg
>
