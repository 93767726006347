<script lang="ts">
  import Login from '../auth/Login.svelte';
</script>

<div class="header">
  <Login />
</div>

<style lang="scss">
  .header {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.4rem 1.2rem;
  }
</style>
